<template>
  <div class="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          v-tooltip="`Bold`"
          @click="commands.bold"
        >
          <i class="fas fa-bold"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          v-tooltip="`Italic`"
          @click="commands.italic"
        >
          <i class="fas fa-italic"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          v-tooltip="`Strikethrough`"
          @click="commands.strike"
        >
          <i class="fas fa-strikethrough"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          v-tooltip="`Underline`"
          @click="commands.underline"
        >
          <i class="fas fa-underline"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          v-tooltip="`Paragraph`"
          @click="commands.paragraph"
        >
          <i class="fas fa-paragraph"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          v-tooltip="`Header 1`"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          v-tooltip="`Header 2`"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          v-tooltip="`Header 3`"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          v-tooltip="`Bullet List`"
          @click="commands.bullet_list"
        >
          <i class="fas fa-list-ul"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          v-tooltip="`Ordered List`"
          @click="commands.ordered_list"
        >
          <i class="fas fa-list-ol"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          v-tooltip="`Blockquote`"
          @click="commands.blockquote"
        >
          <i class="fas fa-quote-right"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          v-tooltip="`Horizontal Line`"
          @click="commands.horizontal_rule"
        >
          ━
        </button>

        <button
          type="button"
          class="menubar__button"
          v-tooltip="`Undo`"
          @click="commands.undo"
        >
          <i class="fas fa-undo"></i>
        </button>

        <button
          type="button"
          class="menubar__button"
          v-tooltip="`Redo`"
          @click="commands.redo"
        >
          <i class="fas fa-redo"></i>
        </button>
      </div>
    </editor-menu-bar>

    <editor-content class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar, Extension } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History
} from "tiptap-extensions";
export default {
  components: {
    EditorContent,
    EditorMenuBar
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }

      this.editor.setContent(this.value);
    }
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History()
        ],
        content: "",

        onUpdate: ({ getHTML }) => {
          // get new content on update
          this.$emit("input", this.editor.getHTML());
        }
      })
    };
  },
  mounted() {
    this.editor.setContent(this.value);
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {}
};
</script>

<style lang="scss">
.editor {
  border: solid 1px #ddd;
  border-radius: 5px;
  width: 100%;

  .menubar {
    display: flex;
    flex-wrap: wrap;
    background: #edf2f8;
    border-radius: 5px 5px 0 0;
    color: #454545;
    .menubar__button {
      margin: 4px 2px;
      &.is-active {
        background: darken(#edf2f8, 10);
      }
    }
  }
}
.ProseMirror {
  padding: 6px 12px;
  min-height: 160px;
  max-height: 450px;
  overflow-y: scroll;
}
.ProseMirror-focused {
  outline: solid 1px $color-main;
  border-radius: 5px;
}
</style>